<template>
  <div>
    <b-modal id="modal-update-agency" cancel-variant="outline-secondary" :ok-title="$t('Update')"
      :cancel-title="$t('Close')" size="lg" no-enforce-focus ok-variant="primary" centered modal-class="modal-primary"
      :title="$t('Update Agency')" @ok="confirmUpdate" @hidden="agency == null">
      <!-- form -->
      <validation-observer ref="updateAgencyModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="email">
            <label class="mb-1">{{ $t("Email") }} <span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="email" rules="required|email">
              <b-form-input id="email" :disabled="true" type="text" v-model="agency.email"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="username">
            <label class="mb-1">{{ $t("Username") }} <span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="username" rules="required|min:2|max:50">
              <b-form-input id="username" type="text" disabled v-model="agency.username"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="phone">
            <label class="mb-1">{{ $t("Phone") }} <span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="phone" rules="required|min:9|max:12">
              <b-form-input id="phone" type="number" :disabled="true" v-model="agency.phone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="password">
            <label class="mb-1">{{ $t("Password") }}</label>
            <validation-provider #default="{ errors }" name="password" type="password" rules="min:6|max:50">
              <b-form-input id="password" v-model="agency.password" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="commissionType" class="mb-2">
            <label class="mb-1">{{ $t("Loại hoa hồng") }} <span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="commissionType" rules="required">
              <v-select v-model="agency.commissionType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="true" :options="commissionTypeOptions" :reduce="option => option.value" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="discount" v-if="agency.commissionType === 2">
            <label class="mb-1">{{ $t("Discount_agency") }}</label>
            <validation-provider #default="{ errors }" name="discount" type="text" rules="required|between:1,100">
              <b-form-input id="discount" type="number" v-model="agency.discount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Status -->
          <b-form-group label-for="status">
            <label class="mb-1 d-block">{{ $t("Status") }}</label>
            <b-form-radio v-model="agency.status" name="status" value="1" inline>
              Active
            </b-form-radio>
            <b-form-radio v-model="agency.status" name="status" value="2" inline>
              Inactive
            </b-form-radio>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import baseUrl from "@/libs/base-url";
import Editor from "@tinymce/tinymce-vue";
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Editor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    agencyDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      agency: {
        id: "",
        email: "",
        username: "",
        phone: "",
        discount: "",
        password: "",
        status: "",
        commissionType: ""
      },
    };
  },
  watch: {
    agencyDetail: {
      handler(newVal) {
        if (newVal) {
          this.agency = {
            id: newVal.id,
            username: newVal.username,
            email: newVal.email,
            phone: newVal.phone,
            discount: newVal.discount,
            password: "",
            status: newVal.status,
            commissionType: newVal.commission_type
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateAgencyModal.validate().then((success) => {
        if (success) {
          this.updateAgency(this.agency);
        }
      });
    },
    async updateAgency(agencyData) {
      if (agencyData.commissionType === 1) {
        agencyData.discount = '';
      }
      await this.$store
        .dispatch("marketing/updateAgency", agencyData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-agency");
                  this.resetModal();
                }, "100");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.agency = {
        id: "",
        email: "",
        username: "",
        phone: "",
        discount: "",
        password: "",
        status: "",
        commissionType: ""
      };
      this.$emit("update: agencyDetail", null);
    },
    confirmUpdate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const commissionTypeOptions = [
			{ label: 'Flexible', value: 1 },
			{ label: 'Fixed', value: 2 },
		];
    return {commissionTypeOptions, baseUrl};
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>