var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-agency","cancel-variant":"outline-secondary","ok-title":_vm.$t('Update'),"cancel-title":_vm.$t('Close'),"size":"lg","no-enforce-focus":"","ok-variant":"primary","centered":"","modal-class":"modal-primary","title":_vm.$t('Update Agency')},on:{"ok":_vm.confirmUpdate,"hidden":function($event){_vm.agency == null}}},[_c('validation-observer',{ref:"updateAgencyModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"email"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Email"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":true,"type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.email),callback:function ($$v) {_vm.$set(_vm.agency, "email", $$v)},expression:"agency.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"username"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Username"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"username","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","type":"text","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.username),callback:function ($$v) {_vm.$set(_vm.agency, "username", $$v)},expression:"agency.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"phone"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Phone"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"phone","rules":"required|min:9|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"number","disabled":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.phone),callback:function ($$v) {_vm.$set(_vm.agency, "phone", $$v)},expression:"agency.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Password")))]),_c('validation-provider',{attrs:{"name":"password","type":"password","rules":"min:6|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.password),callback:function ($$v) {_vm.$set(_vm.agency, "password", $$v)},expression:"agency.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"commissionType"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Loại hoa hồng"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"commissionType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"options":_vm.commissionTypeOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.agency.commissionType),callback:function ($$v) {_vm.$set(_vm.agency, "commissionType", $$v)},expression:"agency.commissionType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.agency.commissionType === 2)?_c('b-form-group',{attrs:{"label-for":"discount"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Discount_agency")))]),_c('validation-provider',{attrs:{"name":"discount","type":"text","rules":"required|between:1,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"discount","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.discount),callback:function ($$v) {_vm.$set(_vm.agency, "discount", $$v)},expression:"agency.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,953911273)})],1):_vm._e(),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v(_vm._s(_vm.$t("Status")))]),_c('b-form-radio',{attrs:{"name":"status","value":"1","inline":""},model:{value:(_vm.agency.status),callback:function ($$v) {_vm.$set(_vm.agency, "status", $$v)},expression:"agency.status"}},[_vm._v(" Active ")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.agency.status),callback:function ($$v) {_vm.$set(_vm.agency, "status", $$v)},expression:"agency.status"}},[_vm._v(" Inactive ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }